import React, { useState, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    catname: Yup.string()
      .label('Category')
      .required()
      .min(4, 'Must have at least 4 characters'),
    catdesc: Yup.string()
      .label('Description')
      .required()
      .min(5, 'Must have at least 5 characters'),
  });
  
const UpdateProductCatForm = ({handleSubmit, updateItem, setShowModal}) => {
  const [errorMsg, setErrorMsg] = useState('');

  const { apiUrl } = useContext(SiteContext);

    return (
      <div>
        <div className='container'>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <Formik
                    initialValues={{
                      id: updateItem.pcid ? updateItem.pcid : '',
                      catname: updateItem.pcatname ? updateItem.pcatname : '',
                      catdesc: updateItem.pcatdesc ? updateItem.pcatdesc : '',
                      picture: updateItem.pcatpicture ? updateItem.pcatpicture : ''

                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm, setShowModal });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}  encype="multipart/form-data">
 
                        <div className='row'>
                          <div className='col-md-12'>
                          <div className='form-group my-2'>
                          <div>
                            <label htmlFor="catname">Category</label>
                          </div>
                          <Field
                            id="catname"
                            name="catname"
                            value={values.catname}
                            placeholder="Category"
                            onBlur={handleBlur('catname')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                          {touched.catname && errors.catname && <div className='py-1'>
                                <span className='text-danger'>{touched.catname && errors.catname}</span>
                          </div>}
                        </div>

                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="catdesc">Description</label>
                            </div>
                            <Field
                              as='textarea'
                              id="catdesc"
                              name="catdesc"
                              value={values.catdesc}
                              placeholder="Description"
                              onBlur={handleBlur('catdesc')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.catdesc && errors.catdesc && <div className='py-1'>
                                <span className='text-danger'>{touched.catdesc && errors.catdesc}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="picture"> Picture</label>
                          </div>
                          <input id="picture" name="picture" type="file" onChange={(event) => {
                                 setFieldValue("picture", event.currentTarget.files[0]);
                            }} />
                          {updateItem && <img src={`${apiUrl}/${updateItem.pcatpicture}`} height={70} width={70} alt="picture" />}

                        </div>

                          </div>
                        </div>
                         
                          <button
                            type='submit'
                            buttonType="outline"
                            onClick={handleSubmit}
                            title="Update"
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                              className='btn btn-primary'
                          >
                              Update
                          </button>
                        
                          </form>
                    )}
                  </Formik>
      </div>
    </div>
    )
}

export default UpdateProductCatForm;