import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer, ProjectGallery } from './';
import { Link, useNavigate } from 'react-router-dom';
import { SiteContext } from '../context';
import { removeTags, getHeading } from '../utils';
import Carousel from 'react-bootstrap/Carousel';
import Accordion from 'react-bootstrap/Accordion'
import Seo from './Seo';

const Home = () => {
    const { userData, apiUrl, siteconfig, isLoggedOn, categories, pages, handleCurrentPage, isMobile } = useContext(SiteContext);

    const navigate = useNavigate();

    const [animationPhotos, setAnimationPhotos] = useState([]);
    const [stories, setStories] = useState([]);
    const [testimonials, setTestimonials] = useState([]);
    const [clientLogos, setClientLogos] = useState([]);
    const [homeServices, setHomeServices] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        getHomeAnimations();
        getArticles();
        getClientLogos();
        getHomeServices();
        getTestimonials();
        handleCurrentPage('home');
    }, [])


    const getHomeAnimations = () => {
        fetch(`${apiUrl}/api/homeanimations.php?action=fetch&type=Shop`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setAnimationPhotos(res.rows);
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getArticles = () => {
        fetch(`${apiUrl}/api/articles.php?action=fetchblog&page=1&limit=3`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setStories(res.rows);
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const getClientLogos = () => {
        const logos = pages.filter((item) => item.cpagemenu === 'Clients');
        setClientLogos(logos);
    }

    const getHomeServices = () => {
        const homeservices = pages.filter((item) => item.cpagemenu === 'Services' && item.cpagename !== 'Services Overview');
        setHomeServices(homeservices);
        setLoading(false);
    }

    const getTestimonials = () => {
        fetch(`${apiUrl}/api/testimonials.php?action=fetch`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                if (res.rows.length > 0) {
                    setTestimonials(res.rows);
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    const processText = (text) => {
        let thetext = removeTags(text);

        thetext = getHeading(thetext, 25);

        return thetext;
    }

    if (loading) {
        return (
            <div className='d-flex justify-content-center align-content-center my-5'>
                <div class="spinner-border text-secondary" role="status" />
            </div>
        )
    }


    return (
        <div>
            <Seo
                title={`Home | ${siteconfig.shopname}`}
                description="Just Web Services provides website development, mobile apps development and web hosting services"
                url={`${apiUrl}`}
                img={`${apiUrl}/${siteconfig.shoplogo}`}
            />
            <Header />

            {animationPhotos.length > 0 &&
                <Carousel style={{ 'backgroundColor': 'black' }}>
                    {animationPhotos.map((item) => {
                        return <Carousel.Item interval={2000} key={item.phid}>
                            <img style={{ 'objectFit': 'fill' }}
                                className="d-block w-100"
                                src={`${apiUrl}/${item.phphoto}`}
                                height={isMobile ? 400 : 600}
                                width='100%'
                                alt={item.phtitle}
                            />
                            <div className='mt-0 p-4 rounded' style={{ 'padding': '5% !important', 'color': '#fff', 'position': 'absolute', 'backgroundColor': '#333', 'opacity': 0.8, 'left': isMobile ? '5%' : '10%', 'bottom': isMobile ? '10%' : '40%', 'width': isMobile ? '90%' : '40%' }}>
                                <p className={`text-white ${isMobile ? 'h2' : 'h1'}`}>{item.phtitle}</p>
                                <p className={isMobile ? 'h6' : 'h5'}>{item.phcontent}</p>
                                <div className='my-2 d-none d-md-block'>
                                    <button className='bg-primary border-0 text-white p-2' onClick={() => { handleCurrentPage('services'); navigate('/our-services'); }}>Our Services</button> <button className='bg-white border-0 text-dark p-2 mx-2' onClick={() => { handleCurrentPage('about'); navigate('/page/about'); }}>About Us</button>
                                </div>
                            </div>
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                    })
                    }
                </Carousel>}


            <div className='container'>

                <div className='rounded my-1 py-3 border border-light'>
                    <div className='row p-3'>
                        {homeServices.map((item) => {
                            return <div key={item.cpageid} className='col-md-4 col-sm-12 p-3'>
                                <div className='border-primary border-3 shadow border-top text-dark p-2 rounded'>
                                    <img style={{ 'objectFit': 'contain', 'height': 300 }} src={`${apiUrl}/${item.cpagephoto}`} height={300} width='100%' />
                                    <div className='p-3'>
                                        <h4>{item.cpagename}</h4>
                                        <div style={{ 'height': isMobile ? 200 : 220 }} className='row d-flex align-content-between'>
                                            <div>
                                                <p className='text-muted'>
                                                    {processText(item.cpagecontent)}
                                                </p>
                                            </div>
                                            <div>
                                                <button onClick={() => navigate(`/our-services/${item.cpagelinkname}`, { state: { service: item } })} className='my-3 border-0 bg-primary text-white py-2 px-4'>Read more</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                        }
                    </div>
                </div>

            </div>

            <div className='bg-info'>
                <div className={isMobile ? 'container p-3' : 'container p-5'}>
                    <div className='row'>
                        <div className='col-md-3 my-2 py-2 h2'>Technology Stack & Development Tools</div>
                        <div className='col-md-6 my-2 py-2 text-center'>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>PHP</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>CSS</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>HTML</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>REACT</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>JAVASCRIPT</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>REACT NATIVE</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>MYSQL</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>IOS</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>ANDROID</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>JQUERY</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>ANDROID STUDIO</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>XCODE</button>
                            <button className='bg-light text-dark p-2 m-1 rounded-2 border-0'>VSCODE</button>
                        </div>
                        <div className='col-md-3 d-flex justify-content-center align-items-center h2'>
                            <button className='btn btn-primary btn-sm px-3 py-2 h6 small text-decoration-none border-0 rounded-2 text-white' onClick={() => navigate('/request-quote')}><i className='bi bi-envelope mx-1'></i> REQUEST A QUOTE</button>
                        </div>
                    </div>
                </div>
            </div>


            <div style={{ 'backgroundColor': 'azure' }} className={isMobile ? 'px-2 py-4 my-3' : 'px-3 py-5 my-3'}>
                <div className='container py-3'>
                    <div className='row'>
                        <div className='col-md-6 p-4'>

                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>WHO WE ARE</Accordion.Header>
                                    <Accordion.Body>
                                        Just Web Services is a Nigerian based website and mobile app development outfit that has been helping both individuals and organisations set up web solutions that are both reliable and cost effective.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>WHY CHOOSE US</Accordion.Header>
                                    <Accordion.Body>
                                        We have over 18 years experience in website development and web hosting business so we understand every single aspect of the business.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>CORE VALUES</Accordion.Header>
                                    <Accordion.Body>
                                        Honesty | Integrity | Credibility.
                                    </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                    <Accordion.Header>MISSION</Accordion.Header>
                                    <Accordion.Body>
                                        We want to become the first option when Nigerians want to set up websites & mobile apps and also when they need reliable web hosting for their websites.
                                    </Accordion.Body>
                                </Accordion.Item>

                            </Accordion>


                        </div>
                        <div className='col-md-6'>
                            <img className='rounded img-fluid' src='../aboutus.jpeg' width='100%' height={isMobile ? 250 : 350} alt='about us' />
                        </div>
                    </div>
                </div>
            </div>

            <ProjectGallery />


            <div className='px-3 py-5 my-3 text-white bg-danger'>
                <div className='container py-3'>
                    <div className='row'>
                        <div className='col-md-6'>
                            <img className='rounded' src='../support-maintenance.png' width='100%' height={isMobile ? 250 : 350} alt='indigenous talents' />
                        </div>
                        <div className='col-md-6 p-4'>
                            <h2>Development and Support are local</h2>

                            <p>
                                Every aspect of the development is carried out in Nigeria so you are just a call or an email away from engaging us for your next project.
                            </p>
                            <p>
                                In the unlikely event that you encounter a problem or require an urgent additional feature you will reach us via all communication channels without any issues or time zone problems.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className='bg-white border-bottom my-4'>
                <div className='container'>
                    <h2 className='py-3'>Some of our clients</h2>
                    <div className='bg-light text-center'>
                        {clientLogos && clientLogos.map((item, i) => {
                            return (
                                <img style={{ 'objectFit': 'contain', 'height': '70px' }} className='img-fluid mx-1 p-3 rounded' src={`${apiUrl}/${item.cpagephoto}`} height={100} width={100} alt={item.cpagename} />
                            )
                        })}
                    </div>

                </div>
            </div>


            <div className='bg-white'>
                <div className='container'>
                    <h2 className='py-3'>Latest posts from our blog</h2>

                    <div className='row'>

                        {stories.length > 0 && stories.map((item, i) => {
                            return (
                                <div key={i} className='col-md-4 p-3 bg-light'>
                                    <Link className='text-decoration-none text-dark text-left' to={`/post/${item.titleslug}`} state={{ article: item }}>
                                        <div className='d-flex justify-content-center mb-2 p-3'>
                                            <img style={{ 'height': '250px', 'objectFit': 'cover' }} className='rounded img-fluid' src={`${apiUrl}/${item.artphoto}`} width={isMobile ? '80%' : '100%'} height={250} alt={item.title} />
                                        </div>
                                        <h4 className='px-3'>{getHeading(item.title, 10)}</h4>
                                    </Link>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            <div style={{ 'backgroundColor': 'black' }} className='py-5'>

                <div className={`${isMobile ? 'w-75' : 'w-50'} my-3 py-2 mx-auto`}>
                    <h3 className='text-center text-white'>What people say about us</h3>
                    <div className={`${isMobile ? 'w-75' : 'w-25'} border-danger border-bottom`} />
                </div>

                {testimonials.length > 0 &&
                    <Carousel style={{ 'backgroundColor': 'black' }} className='w-75 mx-auto text-center text-white'>
                        {testimonials.map((item) => {
                            return <Carousel.Item interval={2000} key={item.tid}>

                                <div className={`${isMobile ? 'w-100' : 'w-75'} mx-auto mb-5 text-center`}>
                                    <div className='py-1'><q>{item.tmessage}</q></div>
                                    <div className='my-3'>
                                        {item.tphoto !== '' ?
                                            <img className="rounded-circle"
                                                src={`${apiUrl}/${item.tphoto}`}
                                                height={75}
                                                width={75}
                                                alt={item.phtitle}
                                            /> :
                                            <i className='bi bi-person h1'></i>
                                        }
                                        <div className='h6'>{item.tcustomer} - {item.trole}</div>
                                    </div>

                                </div>

                                <Carousel.Caption>
                                </Carousel.Caption>
                            </Carousel.Item>
                        })
                        }
                    </Carousel>}
            </div>

            <Footer />
        </div>
    )
}

export default Home;