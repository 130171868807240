import React, { useState, useEffect, useContext } from 'react';
import { Header, Footer } from './';
import { Pagination } from '../components';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { DashboardMenu } from '../components';
import { SiteContext } from '../context';
import ReactModal from 'react-modal';

const Articles = () => {

  let navigate = useNavigate();

  const { apiUrl } = useContext(SiteContext);

  const userData = JSON.parse(localStorage.getItem('user'));

  const [articles, setArticles] = useState([]);
  const [total, setTotal] = useState(null);
  const [limit, setLimit] = useState(25);
  const [page, setPage] = useState(1);
  const [cangoBack, setCangoBack] = useState(true);
  const [cangoForward, setCangoForward] = useState(true);
  const [updateItem, setUpdateItem] = useState({});
  const [itemSelected, setItemSelected] = useState('');
  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    getArticles();
  }, [page, limit])

  const getArticles = () => {
    fetch(`${apiUrl}/api/articles.php?action=fetch&role=${userData.role}&page=${page}&limit=${limit}`, {
      method: 'get'
    }).then((res) => {
      return res.json()
    }).then((res) => {

      if (res.status === 200) {
        if (res.rows.length > 0) {
          setArticles(res.rows);
          setTotal(res.count);
        }
      } else if (res.status === 400) {
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        }, 3000)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  const doPrevious = async () => {

    if (parseInt(page) > 1) {
      await setPage(() => parseInt(page - 1));
      setCangoBack(true);
      setCangoForward(true);
    } else {
      setCangoBack(false);
      setCangoForward(true);
    }
  }

  const doNext = async () => {

    if (parseInt(page * limit) < total) {
      await setPage(() => parseInt(page + 1));
      setCangoForward(true);
      setCangoBack(true);
    } else {
      setCangoForward(false);
      setCangoBack(true);
    }
  }

  const handleChange = (event) => {
    let newLimit = event.target.value;
    setLimit(newLimit);
  }

  const handleAdd = () => {
    navigate('/addarticle');
  }

  const handleUpdate = (article) => {
    navigate('/updatearticle', { state: { 'article': article, 'action': 'update' } });
  }

  const handleDelete = (artid) => {
    setItemSelected(artid);
    setShowModal(true);
  }


  const doDelete = (itemSelected) => {
    let formData = new FormData();

    formData.append('artid', itemSelected);
    formData.append('role', userData.role);
    formData.append('action', 'delete');

    fetch(`${apiUrl}/api/articles.php`, {
      method: 'post',
      body: formData
    }).then((res) => {
      return res.json()
    }).then((res) => {
      if (res.status === 200) {
        setShowModal(false);
        setMsg(res.msg)
        setTimeout(() => {
          setMsg('')
        }, 3000)
        getArticles();
      } else if (res.status === 400) {
        setErrorMsg(res.msg)
        setTimeout(() => {
          setErrorMsg('')
        }, 3000)
      }
    }).catch((err) => {
      console.log(err)
    })
  }


  return (
    <div>
      <ReactModal
        isOpen={showModal}
        style={{
          overlay: {
            flex: 1,
            justifyContent: 'center',
            alignContent: 'center',
            position: 'fixed',
            top: '10%',
            bottom: '10%',
            left: '25%',
            right: '25%',
            width: '50%',
            height: 250,
            backgroundColor: 'rgba(255, 255, 255, 0.75)'
          },
          content: {
            position: 'absolute',
            top: '20px',
            left: '20px',
            right: '20px',
            bottom: '20px',
            border: '1px solid #ccc',
            background: '#fff',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
            borderRadius: '4px',
            outline: 'none',
            padding: '20px'
          }
        }}>
        <div>
          <p className='h3 my-1 py-1'>Confirm deletion</p>
          <p className='my-1 py-2'>Action cannot be reversed!</p>
          <span className='btn btn-primary' onClick={() => doDelete(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
        </div>
      </ReactModal>
      <Header />
      <div>
        <div className='row'>
          <div className='col-md-2 bg-light'>
            <DashboardMenu />
          </div>
          <div className='col-md-10'>
            <div className='container my-2'>
              <h3>Articles</h3>
              <div className='my-2 d-flex justify-content-between'>
                <div className='align-items-center'>
                  {msg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{msg}</span></div> : null}
                  {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                </div>
                <div>
                  <button className='btn btn-primary' onClick={() => handleAdd()}>New Article</button>
                </div>
              </div>

              {articles.length > 0 && <Pagination limit={limit} page={page} total={total} cangoBack={cangoBack} cangoForward={cangoForward} handleChange={handleChange} doNext={doNext} doPrevious={doPrevious} />}

              {articles.length > 0 &&
                <div className='container p-3'>
                  <div className='row border p-1 bg-light'>
                    <div className='col-1'>No</div>
                    <div className='col-7'>Item</div>
                    <div className='col-2'>Status</div>
                    <div className='col-2'>Action</div>
                  </div>
                  {articles.map((article, i) => {
                    return (
                      <div key={i} className='row border-bottom py-1'>
                        <div className='col-1'>{++i}</div>
                        <div className='col-7'><button className='btn-white bg-white border-0 text-primary' onClick={() => navigate(`/posts/post/${article.titleslug}`)} >{article.title}</button> <br />
                          <i className='bi bi-person'></i>: {article.author} | <i className='bi bi-clock'></i>: {moment(article.createdAt).format('Do MMM YYYY h:mma')}</div>
                        <div className='col-2'>{article.artstatus == 1 ? 'Published' : 'Draft'}</div>
                        <div className='col-2'><a onClick={() => handleUpdate(article)}><i className='bi bi-pencil text-dark h4'></i></a> <a onClick={() => handleDelete(article.artid)}><i className='bi bi-trash text-danger h4'></i></a> </div>
                      </div>
                    )
                  })}
                </div>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Articles;