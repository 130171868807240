import React, { useContext, useState } from 'react';
import { Header, Footer } from './';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { NavigationBar } from '../components';
import { useNavigate } from 'react-router-dom';
import { SiteContext } from '../context';
import Seo from './Seo';

const validationSchema = Yup.object().shape({
    yourname: Yup.string()
        .label('Your name')
        .required()
        .min(3, 'Must have at least 3 characters'),
    youremail: Yup.string()
        .label('Your email address')
        .email('Enter a valid email')
        .required(),
    phone: Yup.string()
        .label('Phone')
        .required(),
    servicetype: Yup.string()
        .label('Service type')
        .required(),
    subject: Yup.string()
        .label('Subject')
        .required(),
    message: Yup.string()
        .label('Message')
        .required()
});


const RequestQuote = () => {

    const { siteconfig, apiUrl } = useContext(SiteContext);

    const navigate = useNavigate();

    const [msg, setMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [showForm, setShowForm] = useState(true);

    const handleSubmit = (values, { setSubmitting, resetForm, setShowModal, selectedPackage, selectedUser }) => {

        setLoading(true);

        let formData = new FormData();

        formData.append('name', values.yourname);
        formData.append('email', values.youremail);
        formData.append('phone', values.phone);
        formData.append('servicetype', values.servicetype);
        formData.append('subject', values.subject);
        formData.append('message', values.message);
        formData.append('yourchoice', values.yourchoice);
        formData.append('action', 'send');

        return fetch(`${apiUrl}/api/quote_requests.php`, {
            method: 'post',
            body: formData
        })
            .then((res) => res.json())
            .then(async (res) => {

                console.log(res);

                if (res.status === 200) {
                    resetForm({});
                    setSubmitting(false);
                    setMsg(res.msg);
                    setShowForm(false);
                    setLoading(false);
                } else if (res.status === 400) {
                    setErrorMsg(res.msg);
                    setTimeout(() => {
                        setErrorMsg('');
                    }, 5000);
                    setSubmitting(false);
                    setLoading(false);
                } else {
                    alert(
                        'Message could not be sent',
                        'Error connection to the server please check your details and try again',
                        [{ text: 'Okay' }]
                    );
                }
            })
            .catch((error) => {
                console.log('Api call error', error.message);
            });
    };


    return (
        <div>
            <Seo
                title={`Request a quote | ${siteconfig.shopname}`}
                description="Request a quote for your next project from website development to mobile apps development and customer software development"
                url={`${apiUrl}/request-quote`}
                img={`${apiUrl}/${siteconfig.shoplogo}`}
            />
            <Header />

            <div className='container py-3'>
                <NavigationBar section={'Request a quote'} goback={false} />
                <div className='row p-3'>
                    <div className='col-md-8 mt-2'>
                        <h4>Request a quote</h4>
                        <p>Please provide as much detail as possible so as to enable us evaluate your request. </p>
                        <p className='text-muted small fst-italic'>Expect a response in less than 24hrs</p>
                        <div className='my-2 d-flex justify-content-between'>
                            <div className='align-items-center'>
                                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                            </div>
                        </div>

                        {loading && <div className='d-flex justify-content-center align-content-center my-5'>
                            <div class="spinner-border text-secondary" role="status" />
                        </div>}


                        {showForm ? <Formik
                            initialValues={{
                                yourname: '',
                                youremail: '',
                                phone: '',
                                yourchoice: '',
                                servicetype: '',
                                subject: '',
                                message: '',
                            }}
                            validationSchema={validationSchema}
                            onSubmit={(values, { setSubmitting, resetForm }) => {
                                handleSubmit(values, { setSubmitting, resetForm });
                            }}
                        >
                            {({
                                handleChange,
                                values,
                                handleSubmit,
                                errors,
                                isValid,
                                isSubmitting,
                                handleBlur,
                                touched,
                                setFieldValue
                            }) => (
                                <div>
                                    <form onSubmit={handleSubmit} encype="multipart/form-data">
                                        <div className='my-2'>

                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="yourname">Name <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    id="yourname"
                                                    name="yourname"
                                                    value={values.yourname}
                                                    placeholder="Your name"
                                                    onBlur={handleBlur('yourname')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                    className="form-control"
                                                />
                                                {touched.yourname && errors.yourname && <div className='py-1'>
                                                    <span className='text-danger'>{touched.yourname && errors.yourname}</span>
                                                </div>}
                                            </div>

                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="youremail">Email address <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    id="youremail"
                                                    name="youremail"
                                                    value={values.youremail}
                                                    placeholder="Your email"
                                                    onBlur={handleBlur('youremail')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                    className="form-control"
                                                />
                                                {touched.youremail && errors.youremail && <div className='py-1'>
                                                    <span className='text-danger'>{touched.youremail && errors.youremail}</span>
                                                </div>}
                                            </div>

                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="phone">Phone number <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    id="phone"
                                                    name="phone"
                                                    value={values.phone}
                                                    placeholder="Your phone"
                                                    onBlur={handleBlur('phone')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                    className="form-control"
                                                />
                                                {touched.phone && errors.phone && <div className='py-1'>
                                                    <span className='text-danger'>{touched.phone && errors.phone}</span>
                                                </div>}
                                            </div>

                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="servicetype">Service type <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    as='select'
                                                    id="servicetype"
                                                    name="servicetype"
                                                    value={values.servicetype}
                                                    onBlur={handleBlur('servicetype')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                    className="form-control"
                                                >
                                                    <option value=''>Select ...</option>
                                                    <option value='Mobile app development'>Mobile app development</option>
                                                    <option value='Website development'>Website development</option>
                                                    <option value='Web hosting'>Web hosting</option>

                                                </Field>

                                            </div>


                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="subject">Subject <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    id="subject"
                                                    name="subject"
                                                    value={values.subject}
                                                    placeholder="Subject"
                                                    onBlur={handleBlur('subject')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10,
                                                    }}
                                                    className="form-control"
                                                />
                                                {touched.subject && errors.subject && <div className='py-1'>
                                                    <span className='text-danger'>{touched.subject && errors.subject}</span>
                                                </div>}
                                            </div>

                                            <div className='form-group my-3'>
                                                <div className='h6'>
                                                    <label htmlFor="message">Message <span style={{ 'position': 'relative', 'top': -1 }} className='h5'>*</span></label>
                                                </div>
                                                <Field
                                                    as='textarea'
                                                    id="message"
                                                    name="message"
                                                    value={values.message}
                                                    placeholder="Message"
                                                    onBlur={handleBlur('message')}
                                                    autoCapitalize="none"
                                                    style={{
                                                        padding: 10
                                                    }}
                                                    rows='4'
                                                    className="form-control"
                                                />
                                                {touched.message && errors.message && <div className='py-1'>
                                                    <span className='text-danger'>{touched.message && errors.message}</span>
                                                </div>}
                                            </div>
                                        </div>

                                        <button
                                            type='submit'
                                            buttonType="outline"
                                            onClick={handleSubmit}
                                            title="Submit"
                                            disabled={!isValid || isSubmitting}
                                            loading={isSubmitting}
                                            className='btn btn-primary'
                                        >
                                            Submit
                                        </button>

                                    </form>
                                </div>
                            )}
                        </Formik> :
                            <div className='my-1 py-1'>
                                {msg !== '' ? <div className='p-2'><span className='p-2 text-dark'>{msg}</span></div> : null}
                            </div>}

                    </div>

                    <div className='col-md-4'>
                        <h5>Working Time</h5>
                        <p className='p-1'><i className='bi bi-clock'></i>: MON-SAT 06:00-18:00</p>
                        <p className='p-1'><i className='bi bi-phone'></i>: {siteconfig.shopphone}</p>
                        <p className='p-1'><i className='bi bi-envelope'></i>: {siteconfig.shopemailaddress}</p>

                        <h5>Got Questions?</h5>
                        <div className='bg-danger text-white p-3 rounded-1'>
                            <p className='p-1'>Submit your question or inquiry online and we would get back to you shortly</p>
                            <button className='p-2 border-0 rounded-3' onClick={() => navigate('/contact')}>Contact us</button>
                        </div>

                    </div>
                </div>

            </div>
            <Footer />
        </div>
    )
}

export default RequestQuote;