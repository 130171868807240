import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { SiteContext } from '../context';

const Cart = () => {

    let navigate = useNavigate();

    const { apiUrl, formatAmount, cart, getOrderTotal, manageCart } = useContext(SiteContext);
  
      return (
        <div>
        <div className='my-3 py-3'>
            <h4>Shopping Cart</h4>

            {cart.length > 0 ? <div>
                {cart.map((item,i) => {
              
              return (
              <div key={i}>
                  <div className='row border border-light bg-light rounded py-2 m-1'>
                      <div className='col-8'>
                      <span className='small'>{item.item}</span> <br /><span className='text-muted small'>x{item.qty} ({item.price !== 0 && formatAmount(item.price)})</span>
                      </div>
                      <div className='col-4'>
                      <span className='small'>{item.total !== 0 && formatAmount(item.total)}<br />
                      <span onClick={() => manageCart('delete', item.id)}><i className='bi bi-trash text-danger h5'></i></span></span>
                      </div>
                  </div>
              </div>
              )}) 
              }

              <div className='px-2 my-1 py-1 d-flex justify-content-between'>
                  <span className='h6'>Total: {formatAmount(getOrderTotal())}</span>
                  <button className='btn-primary text-white h6 text-danger rounded' onClick={() => manageCart('clear')}>Empty your cart</button>
                  </div>

            </div>
            : <div>
                <p>Your cart is empty</p>
                </div>}
        </div>
        </div>
    )
}

export default Cart;