import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { NavigationBar, Cart } from '.';
import { SiteContext } from '../context';
import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
    paymethod: Yup.string()
      .required('Choose a payment method please')
  });

const ProductDetail = () => {

    const { userData, apiUrl, formatAmount, cart, getOrderTotal, manageCart, singleProduct } = useContext(SiteContext);

    const [item, setItem] = useState({});
    const [cartContents, setCartContents] = useState([]);
    const [orderTotal, setOrderTotal] = useState('');
    const [msg, setMsg] = useState('');
    const [canOrder, setCanOrder] = useState(false);

    let {slug} = useParams();
    let navigate = useNavigate();
  

    useEffect(() => {
      setCartContents(cart);
      setOrderTotal(getOrderTotal);
      cart.length > 0 && setCanOrder(true);
    },[cart]);

    useEffect(() => {
      getProducts();
  },[slug])

  const getProducts = () => {
      setCanOrder(false);
      fetch(`${apiUrl}/api/products.php?action=fetchsingle&slug=${slug}`, {
        method:'get'
      }).then((res) => res.json()
      ).then((res) => {
          if(res.status === 200) {
              setItem(res.product)
          } else if (res.status === 400) {
              setMsg('No products found')
              setTimeout(() => {
                setMsg('')
              },3000)
          }
      }).catch((err) => {
          console.log(err)
      })
  }


    const handleSubmit = (values, {setSubmitting, resetForm}) => {

        let formData = new FormData();

        formData.append('productid', singleProduct ? item.id : '');
        formData.append('productname', singleProduct ? item.name : '');
        formData.append('orderdetails', singleProduct ? [] : JSON.stringify(cart));
        formData.append('amount', singleProduct ? item.price : orderTotal);
        formData.append('customerid', userData.id);
        formData.append('customername', userData.name);
        formData.append('customerphone', userData.phone);
        formData.append('customeraddress', `${userData.address} ${userData.residencestate}`);
        formData.append('customerlatitude', userData.customerlatitude);
        formData.append('customerlongitude', userData.customerlongitude);
        formData.append('paymode', values.paymethod);
        formData.append('action', 'create');

        fetch(`${apiUrl}/api/orders.php`, {
            method: 'post',
            body: formData
        })
        .then((res) => res.json())
        .then((response) => {
            if(response.status === 200) {
                setMsg(response.msg);
                setTimeout(() => {
                  setMsg('');
                  },5000);
                  setCanOrder(false);
                  !singleProduct && manageCart('clear');
                  navigate(`/ordercomplete/${response.orderref}`, {state: {'pay': values.paymethod}});
                } else if(response.status === 400){
                setMsg(response.msg);
                setTimeout(() => {
                  setMsg('');
                  },5000);  
              }
        })
        .catch((err) => {
            alert(err)
        })
    
    }

    const getItemTotal = (id) => {
      const cartItems = cartContents.slice();
      const item = cartItems.filter((a) => a.id == id);

      if(item.length > 0) {
        return item[0].qty;
      } else {
        return '1';
      }
    }

    
    return (
        <div>

        <div className='container-fluid'>

        {item ? <div className='row my-1 py-1'>

        <div className='mt-3 p-2 bg-light'>
            <Link to='/'>Home</Link> / <Link to='/products'>Products</Link> / <span>{item.name}</span>  
        </div>

        {msg !== '' ? <div className='my-2'><span className='p-2 my-4 border border-info text-dark'>{msg}</span></div> : null}

            
            <div className='col-md-7 p-1'>
                <div className='row rounded m-1 p-1'>
                    <div className='col-md-5 d-flex justify-content-center align-items-center'>
                            <img className='img-fluid rounded mr-2' src={`${apiUrl}/${item.picture}`} height={250} width='100%' alt={item.name} />
                    </div>
                    <div className='col-md-7'>
                            <p className='h3'>{item.name}</p>
                            <p className='text-muted sm'>Size: {item.size}</p>
                            <p className='text-muted sm'>SKU: {item.sku}</p>
                            <p className='text-muted sm'>{item.status == 1 ? 'Available' : 'Unavailable'}</p>
                            <p className='h5'> {formatAmount(item.price)}</p>    
                            {singleProduct ? <button className='btn p-2 bg-dark text-white' onClick={() => setCanOrder(true)}>Order now</button> : <p className='h5'>Qty: <button className='btn btn-white border border-dark' onClick={() => manageCart('-', item.id, item.name, item.price)}><span className='h6'>-</span></button> <button  className='btn btn-white border border-dark'><span className='h6'>{getItemTotal(item.id)}</span></button> <button  className='btn btn-white border border-dark' onClick={() => manageCart('+', item.id, item.name, item.price)}><span className='h6'>+</span></button></p>}    
                    </div>
                    <div className='my-3'>
                      <h4>Description</h4>
                        <div dangerouslySetInnerHTML={{__html: item.description}} />
                    </div>
                </div>    
            </div>

            <div className='col-md-5 p-1'>
              {userData ? 
              <div>
                <div className='border border-secondary bg-light rounded m-2 p-3'>
                  
                  {!singleProduct && <div className="my-1 p-2 bg-info"><Cart /></div>}

                  <h5 className='my-1'>Current address</h5>
                    {userData.address}, {userData.residencestate} state <button className='btn-sm' onClick={() => navigate('/profile')}><i className='bi bi-pencil'></i> update</button>

                    <h6 className='my-2'>Estimated delivery time: 24hrs</h6>
                    

                    <div className='my-2 justify-content-center align-items-center'>
                        {canOrder && <div>
                    
                    {singleProduct ? 
                    <Formik
                    initialValues={{
                      paymethod:'Card'
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                    }) => (
                      <form onSubmit={handleSubmit} encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-12'>


                        <div className='form-group my-1'>
                          <label>
                          <Field type="radio" name="paymethod" value="Cash" /> Cash
                          </label>
                          <label className='px-3'>
                          <Field type="radio" name="paymethod" value="Card" /> Card
                          </label>
                        </div>

                          </div>
                        </div>
                        <button
                            style={{'width': '100%'}}
                            type='submit'
                            onClick={handleSubmit}
                            title="Submit order"
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary my-1 py-2'
                          >
                              Submit order
                          </button>
                        
                          </form>
                    )}
                  </Formik> : 
                    <button style={{'width': '100%'}} className='btn-dark py-1 text-white h4 rounded' onClick={() => navigate('/checkout')}>Checkout</button>}

                    </div>}                
                    </div>
                </div>    
              </div> : 
              <div>
                  {!singleProduct && <div className="my-1 p-2 bg-info"><Cart /></div>}

                <div className='d-flex justify-content-between mt-3 py-2'>
                  <Link className='btn btn-primary' to='/login'>Login to place order</Link> 
                <Link className='btn btn-secondary' to='/register'>Signup</Link>
                </div>
                
              </div>}
            </div>

            </div> : 
            <div>
              <div className='my-1 py-1'> {msg}</div>

              <p>Product not found</p>  
            </div>}
            </div>
        </div>
    )
}

export default ProductDetail;