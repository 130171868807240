import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NavigationBar, CategoriesMenu } from '../components';
import { SiteContext } from '../context';

const ProductsCategory = () => {

    const { apiUrl, formatAmount } = useContext(SiteContext);

    const [items, setItems] = useState([]);
    const [msg, setMsg] = useState('');
    const [errorMsg, setErrorMsg] = useState('');

    let navigate = useNavigate();
    let {slug} = useParams();

    useEffect(() => {
        getProducts();
        window.scrollTo(0,0)
    },[slug])

    const getProducts = () => {

        fetch(`${apiUrl}/api/products.php?action=fetchcat&cat=${slug}`, {
          method:'get'
        }).then((res) => res.json()
        ).then((res) => {
            if(res.status === 200) {
                setItems(res.products)
            } else if (res.status === 400) {
                setMsg('No products found')
                setTimeout(() => {
                  setMsg('')
                },3000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    return (
        <div>

        <div className='container'>
        <NavigationBar section='Products' page={slug} goback={true} />

            {msg !== '' ? <div className='my-2'><span className='p-2 my-2 border border-info text-dark'>{msg}</span></div> : null}

                <div className='row my-3 py-3'>
                {items.map((item,i) => {
                    
                    return (
                    <div key={i} className='col-md-4'>
                        <button className='text-decoration-none text-dark text-center border border-light bg-light rounded py-2 m-2' onClick={() => navigate(`/products/detail/${item.nameslug}`)}>
                            <div className='row'>
                                <div className='p-2'>
                                    <img className='rounded mr-2' src={`${apiUrl}/${item.picture}`} height={250} width='100%' alt={item.name} />
                                </div>
                                <span className='h5'>{item.name}</span><br />
                                <span className='text-muted sm'>{item.category}</span><br />
                                <span className='h6'>{item.price !== 0 && formatAmount(item.price)}</span>
                                <div className='my-1'><button className='text-white bg-dark'>Add to cart</button></div>    
                            </div>
                        </button>    
                    </div>
                    )})
                }
                </div>
            </div>
        </div>
    )
}

export default ProductsCategory;