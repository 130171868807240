import React, {useState, useContext} from 'react';
import moment from 'moment';
import { SiteContext } from '../context';
import { Link, useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';

const TableTransactionUser = ({orders, onCancel}) => {

  const {apiUrl, userData, formatAmount, isMobile} = useContext(SiteContext);
  const [showModal, setShowModal] = useState(false);
  const [itemSelected, setItemSelected] = useState('');

  let navigate = useNavigate();


  const handleCancel = (orderref) => {
    setItemSelected(orderref);
    setShowModal(true);
  }

  const doCancel = (orderref) => {
    onCancel(orderref);
    setItemSelected('');
    setShowModal(false);
  }

    return (
<div>
<ReactModal
      isOpen={showModal}
      style={{
        overlay: {
          flex:1,
          justifyContent: 'center',
          alignContent: 'center',
          position: 'fixed',
          top: isMobile ? '25%' : '20%',
          bottom: isMobile ? '25%' : '30%',
          left: isMobile ? '2%' : '25%',
          right: isMobile ? '2%' : '25%',
          width: isMobile ? '96%' : '50%',
          height: isMobile ? '50%' : '50%',
          backgroundColor: 'rgba(255, 255, 255, 0.75)'
        },
        content: {
          position: 'absolute',
          top: '10px',
          left: '10px',
          right: '10px',
          bottom: '10px',
          border: '1px solid #ccc',
          background: '#fff',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: '20px'
        }
      }}>
        <div>
          <p className='h3 my-1 py-1'>Confirm cancellation</p>
          <p className='my-1 py-2'>Action cannot be reversed!</p>
          <span className='btn btn-primary' onClick={() => doCancel(itemSelected)}>Confirm</span> <span className='btn btn-secondary' onClick={() => setShowModal(false)}>Cancel</span>
        </div>
      </ReactModal>

      <div className='container p-3'>
          <div className='row border p-1 bg-light'>
            <div className='col-1'>No</div>
            <div className='col-7'>Item</div>
            <div className='col-4'>Status</div>
          </div>
          {orders.map((order,i) => {
            return (
              <div key={i} className='row small border-bottom py-1'>
              <div className='col-1'>{++i}</div>
              <div className='col-7'><Link className='btn-white bg-white border-0 text-primary' to={`/order/${order.orderref}`} >Order #{order.orderref}</Link><br />
              {moment(order.createdAt).format('Do MMM YYYY h:mma')}<br />
              A: {formatAmount(order.amount)}<br />
              C: {formatAmount(order.commission)}<br />
              D: {formatAmount(order.driverfee)}<br />
              {userData.role === 'customer' && order.status === 'New' && order.paymentstatus === 'Unpaid' && <button className='btn-light btn-sm' onClick={() => handleCancel(order.orderref)}>Cancel</button>}
              </div>
              <div className='col-4'>O: {order.status}<br />
              P: {order.paymentstatus}<br />
              Customer<br />
              {order.customername}<br />
              <span className='small text-muted'>({order.customerdeliverystatus})</span><br />
              Driver<br />
              {order.drivername}<br />
              {order.driverdeliverystatus != '' && <span className='small text-muted'>({order.driverdeliverystatus})</span>}<br />
              </div>
            </div>  
            )
          })}</div>
      </div>
    )
}

export default TableTransactionUser;