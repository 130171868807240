import React, { useState, useEffect, useContext, useMemo } from 'react';
import { NavigationBar } from '../components';
import moment from 'moment';
import { LatestBlog, Header, Footer, Seo } from './';
import { useParams, Link, useLocation } from 'react-router-dom';
import { formatViews } from '../utils';
import { SiteContext } from '../context';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { InlineShareButtons } from 'sharethis-reactjs';


const Blog = () => {

    let { id } = useParams();

    const { apiUrl, handleCurrentPage, siteconfig, handleLatestArticles } = useContext(SiteContext);

    const [item, setItem] = useState({});
    const [latestArticles, setLatestArticles] = useState([]);
    const [popularArticles, setPopularArticles] = useState([]);
    const [key, setKey] = useState('Latest');
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');

    useEffect(() => {
        doUpdateViews();
        window.scrollTo(0, 0);
        handleCurrentPage('blog');
    }, [id])


    const doUpdateViews = () => {
        setLoading(true);

        fetch(`${apiUrl}/api/articles.php?action=viewsupdate&id=${id}`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                setLoading(false);
                setItem(res.article);
                setPopularArticles(res.popular);
            } else if (res.status === 400) {
                setLoading(false);
                setErrorMsg(res.msg);
                setTimeout(() => {
                    setErrorMsg('');
                }, 5000)
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    const doSanitize = (item) => {
        return item.replace(/<p>/g, "")
            .replace(/<\/p>/g, " ");
    }


    return (
        <div>
            <Header />
            {item.hasOwnProperty('artid') && <Seo
                title={`${item.title} | ${siteconfig.shopname}`}
                description={item.brief.split(' ', 30).join(' ')}
                url={`${apiUrl}/post/${item.titleslug}`}
                img={`${apiUrl}/${item.artphoto}`}
            />}

            <div className='container my-1 p-3'>
                <NavigationBar section={'Blog'} page={item.titleslug} goback={true} />


                <div className='p-2'>
                    <div className='row'>
                        <div className='col-md-8'>

                            {loading ? <div className='d-flex justify-content-center align-content-center my-5'>
                                <div class="spinner-border text-secondary" role="status" />
                            </div> :

                                <div>
                                    <div className='my-2 d-flex justify-content-between'>
                                        <div className='align-items-center'>
                                            {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
                                        </div>
                                    </div>
                                    {item.hasOwnProperty('artid') && <div className='my-1 py-2 rounded'>
                                        <h3 className='py-2'>{item.title}</h3>

                                        <InlineShareButtons
                                            config={{
                                                alignment: 'center',  // alignment of buttons (left, center, right)
                                                color: 'social',      // set the color of buttons (social, white)
                                                enabled: true,        // show/hide buttons (true, false)
                                                font_size: 16,        // font size for the buttons
                                                labels: 'cta',        // button labels (cta, counts, null)
                                                language: 'en',       // which language to use (see LANGUAGES)
                                                networks: [           // which networks to include (see SHARING NETWORKS)
                                                    'facebook',
                                                    'twitter',
                                                    'email',
                                                    'linkedin',
                                                    'whatsapp',
                                                    'messenger'
                                                ],
                                                padding: 12,          // padding within buttons (INTEGER)
                                                radius: 10,            // the corner radius on each button (INTEGER)
                                                show_total: false,
                                                size: 40,             // the size of each button (INTEGER)


                                                // OPTIONAL PARAMETERS

                                                url: `${apiUrl}/post/${item.titleslug}`, // (defaults to current url)
                                                image: `${apiUrl}/${item.artphoto}`,  // (defaults to og:image or twitter:image)
                                                description: doSanitize(item.fullcontent).split(' ', 100).join(' '),       // (defaults to og:description or twitter:description)
                                                title: item.title,            // (defaults to og:title or twitter:title)
                                                message: doSanitize(item.fullcontent).split(' ', 100).join(' '),     // (only for email sharing)
                                                subject: item.title,  // (only for email sharing)
                                                username: "AfamBC" // (only for twitter sharing)
                                            }}
                                        />
                                        <div className='small text-left py-2'> <i className='bi bi-person-circle'></i> {item.author} | <i className='bi bi-clock'></i>: {moment(item.createdAt).format('Do MMM YYYY h:mma')} | <i className='bi bi-eye'></i> {formatViews(item.views)}</div>

                                        <img style={{ 'objectFit': 'cover', 'height': '300px' }} className='my-1' src={`${apiUrl}/${item.artphoto}`} height={250} width='100%' alt="article" /><br />
                                        {item.artphotocaption !== 'null' ? <div className='small text-left py-2 fst-italic'> Caption: {item.artphotocaption}</div> : null}

                                        <div className='py-2' dangerouslySetInnerHTML={{ __html: item.fullcontent }} />

                                    </div>}
                                </div>}

                        </div>
                        <div className='col-md-4'>
                            <Tabs activeKey={key} onSelect={(k) => setKey(k)}>
                                <Tab eventKey="Latest" title="Latest">
                                    <LatestBlog />
                                </Tab>
                                <Tab eventKey="Popular" title="Popular">
                                    {popularArticles.length > 0 && popularArticles.map((populararticle, i) => {
                                        return (
                                            <div style={{ 'height': 90 }} key={i} className='text-decoration-none border-bottom p-3 my-2'>
                                                <Link className='text-primary text-decoration-none' to={`/post/${populararticle.titleslug}`}>
                                                    <div style={{ 'height': 70, 'overflow': 'hidden', 'objectFit': 'cover' }} className='float-start'><img className='align-self-center mx-2 pr-1' src={`${apiUrl}/${populararticle.artphoto}`} height={80} width={80} alt="article" /></div>
                                                    <div className='text-dark'>{populararticle.title}<br />
                                                        <span className='font-italic small'> <i className='bi bi-eye'></i> {formatViews(populararticle.views)}</span>
                                                    </div>
                                                </Link>
                                            </div>
                                        )
                                    })}
                                </Tab>
                            </Tabs>
                        </div>
                    </div>

                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Blog;