import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { formatViews } from '../utils';
import { SiteContext } from '../context';

const LatestBlog = () => {

    const { apiUrl } = useContext(SiteContext);

    const [latestArticles, setLatestArticles] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        doLatest();
    }, [])


    const doLatest = () => {
        setLoading(true);

        return fetch(`${apiUrl}/api/articles.php?action=fetchlatest`, {
            method: 'get'
        }).then((res) => {
            return res.json()
        }).then((res) => {
            if (res.status === 200) {
                setLatestArticles(res.rows);
                setLoading(false);
            } else if (res.status === 400) {
                console.log(res.msg);
                setLoading(false);
            }
        }).catch((err) => {
            console.log(err)
        })
    }


    return (
        <div>
            {loading && <div className='d-flex justify-content-center align-content-center my-5'>
                <div class="spinner-border text-secondary" role="status" />
            </div>}

            {latestArticles.length > 0 && latestArticles.map((latestarticle, i) => {
                return (
                    <div style={{ 'height': 90 }} key={i} className='text-decoration-none border-bottom p-3 my-2'>
                        <Link className='text-primary text-decoration-none' to={`/post/${latestarticle.titleslug}`}>
                            <div style={{ 'height': 70, 'overflow': 'hidden', 'objectFit': 'cover' }} className='float-start'><img className='align-self-center mx-2 pr-1' src={`${apiUrl}/${latestarticle.artphoto}`} height={80} width={80} alt="article" /></div>
                            <div className='text-dark'>{latestarticle.title}</div>
                        </Link>
                    </div>
                )
            })}

        </div>
    )
}

export default LatestBlog;