import React, { useState, useContext } from 'react';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';

const validationSchema = Yup.object().shape({
    name: Yup.string()
      .label('Name')
      .required()
      .min(4, 'Must have at least 4 characters'),
    sku: Yup.string()
      .label('SKU')
      .required()
      .min(5, 'Must have at least 5 characters'),
    description: Yup.string()
      .label('Description')
      .required()
      .min(5, 'Must have at least 11 characters'),
    price: Yup.string()
      .label('Price')
      .required('Please enter amount'),
    size: Yup.string()
      .label('Size')
      .required(),
    status: Yup.string()
      .label('Status')
      .required(),
    picture: Yup.string()
      .required()
      });
  
const ProductForm = ({ handleSubmit, setShowModal}) => {
  const [errorMsg, setErrorMsg] = useState('');
  const [uploadedPic, setUploadedPic] =  useState(null);

  const { apiUrl } = useContext(SiteContext);


    return (
      <div>
        <div className='container'>

          {errorMsg !== '' ? <span className='p-2 my-2 border border-danger text-danger'>{errorMsg}</span> : null}

          <Formik
                    initialValues={{
                      id: '',
                      name: '',
                      sku: '',
                      description: '',
                      price: '',
                      size: '',
                      status: true,
                      picture: '',
                      payaccount: ''
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      handleSubmit(values, { setSubmitting, resetForm });
                      setTimeout(() => {
                        setShowModal(false);
                      },1000);
                    }}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      isValid,
                      isSubmitting,
                      handleBlur,
                      touched,
                      setFieldValue
                    }) => (
                      <form onSubmit={handleSubmit}  encype="multipart/form-data">
                        <div className='row'>
                          <div className='col-md-6'>

                          <div className='form-group my-2'>
                          <div>
                            <label htmlFor="name">Name</label>
                          </div>
                          <Field
                            id="name"
                            name="name"
                            value={values.name}
                            placeholder="Name"
                            onBlur={handleBlur('name')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                          {touched.name && errors.name && <div className='py-1'>
                                <span className='text-danger'>{touched.name && errors.name}</span>
                          </div>}
                        </div>


                          <div className='form-group my-1'>
                            <div>
                              <label htmlFor="description">Description</label>
                            </div>
                            <Field
                              as='textarea'
                              id="description"
                              name="description"
                              value={values.description}
                              placeholder="Description"
                              onBlur={handleBlur('description')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            row='5'
                            />
                          {touched.description && errors.description && <div className='py-1'>
                                <span className='text-danger'>{touched.description && errors.description}</span>
                          </div>}
                        </div>

                        <div className='form-group my-2'>
                          <div>
                            <label htmlFor="picture"> Picture</label>
                          </div>
                          <input id="picture" name="picture" type="file" onChange={(event) => {
                                 setFieldValue("picture", event.currentTarget.files[0]);
                                 setUploadedPic(event.currentTarget.files[0]);
                                }} />
                        </div>

                        {uploadedPic && <img className='my-2' src={URL.createObjectURL(uploadedPic)} class="img-fluid mt-2" width={80} height={80} id="output_image"/>}



                          </div>
                          <div className='col-md-6'>
                          <div className='form-group my-1'>
                          <div>
                            <label htmlFor="price">Price</label>
                          </div>
                            <Field
                              id="price"
                              name="price"
                              value={values.price}
                              placeholder="Price"
                              onBlur={handleBlur('price')}
                              autoCapitalize="none"
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          {touched.price && errors.price && <div className='py-1'>
                                <span className='text-danger'>{touched.price && errors.price}</span>
                          </div>}
                        </div>


                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="sku">SKU</label>
                          </div>
                          <Field
                            id="sku"
                            name="sku"
                            value={values.sku}
                            placeholder="SKU"
                            onBlur={handleBlur('sku')}
                            autoCapitalize="none"
                            style={{
                              padding: 4
                          }}
                          className="form-control"
                          />
                            {touched.sku && errors.sku && <div className='py-1'>
                                <span className='text-danger'>{touched.sku && errors.sku}</span>
                            </div>}
                          </div>

                        <div className='form-group my-1'>
                          <div>
                            <label htmlFor="size">Size</label>
                          </div>
                            <Field
                              id="size"
                              name="size"
                              value={values.size}
                              placeholder="Size"
                              onBlur={handleBlur('size')}
                              secureTextEntry
                              style={{
                                padding: 4
                            }}
                            className="form-control"
                            />
                          
                          {touched.size && errors.size && <div className='py-1'>
                                <span className='text-danger'>{touched.size && errors.size}</span>
                          </div>}
                        </div>

                        <div className='form-group my-1 py-2'>
                          <div>
                            <label htmlFor="status">In stock?</label> <Field type="checkbox" name="status" /> {values.status ? 'Yes' : 'No'}
                          </div>
                        </div>

                          </div>
                        </div>
                         
                         
                          <button
                            type='submit'
                            onClick={handleSubmit}
                            title="Add"
                            disabled={!isValid || isSubmitting}
                              className='btn btn-primary my-2'
                          >
                              Add
                          </button>
                        
                          </form>
                    )}
                  </Formik>
      </div>
    </div>
    )
}

export default ProductForm;