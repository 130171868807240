import React, { useContext, useState } from 'react';
import { Header, Footer } from './';
import { NavigationBar } from '../components';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import { SiteContext } from '../context';
import Seo from './Seo';

const validationSchema = Yup.object().shape({
  yourname: Yup.string()
    .label('Your name')
    .required()
    .min(3, 'Must have at least 3 characters'),
  youremail: Yup.string()
    .label('Your email address')
    .email('Enter a valid email')
    .required(),
  subject: Yup.string()
    .label('Subject')
    .required(),
  message: Yup.string()
    .label('Message')
    .required()
});


const Contact = () => {

  const { siteconfig, apiUrl } = useContext(SiteContext);

  const [msg, setMsg] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [showForm, setShowForm] = useState(true);


  const handleSubmit = (values, { setSubmitting, resetForm, setShowModal, selectedPackage, selectedUser }) => {

    let formData = new FormData();

    formData.append('name', values.yourname);
    formData.append('email', values.youremail);
    formData.append('subject', values.subject);
    formData.append('message', values.message);
    formData.append('yourchoice', values.yourchoice);
    formData.append('action', 'send');

    return fetch(`${apiUrl}/api/contact_process.php`, {
      method: 'post',
      body: formData
    })
      .then((res) => res.json())
      .then(async (res) => {

        console.log(res);

        if (res.status === 200) {
          resetForm({});
          setSubmitting(false);
          setMsg(res.msg);
          setShowForm(false);
        } else if (res.status === 400) {
          setErrorMsg(res.msg);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
          setSubmitting(false);
        } else {
          alert(
            'Message could not be sent',
            'Error connection to the server please check your details and try again',
            [{ text: 'Okay' }]
          );
        }
      })
      .catch((error) => {
        console.log('Api call error', error.message);
      });
  };


  return (
    <div>
      <Seo
        title={`Contact | ${siteconfig.shopname}`}
        description="Contact"
        url={`${apiUrl}/contact`}
        img={`${apiUrl}/${siteconfig.shoplogo}`}
      />
      <Header />

      <div className='container py-3'>
        <NavigationBar section={'Contact'} goback={false} />

        <div className='row p-3'>
          <div className='col-md-6 mt-2'>
            <h5>Office Address</h5>
            <p className='p-1'><i className='bi bi-house'></i>: {siteconfig.shopaddress}<br />{siteconfig.shopstate}, {siteconfig.shopcountry}</p>
            <p className='p-1'><i className='bi bi-phone'></i>: {siteconfig.shopphone}</p>
            <p className='p-1'><i className='bi bi-envelope'></i>: {siteconfig.shopemailaddress}</p>
          </div>
          <div className='col-md-6'>
            <h4>Contact form</h4>
            <div className='my-2 d-flex justify-content-between'>
              <div className='align-items-center'>
                {errorMsg !== '' ? <div className='p-2'><span className='p-2 border border-info text-dark'>{errorMsg}</span></div> : null}
              </div>
            </div>

            {showForm ? <Formik
              initialValues={{
                yourname: '',
                youremail: '',
                yourchoice: '',
                subject: '',
                message: '',
              }}
              validationSchema={validationSchema}
              onSubmit={(values, { setSubmitting, resetForm }) => {
                handleSubmit(values, { setSubmitting, resetForm });
              }}
            >
              {({
                handleChange,
                values,
                handleSubmit,
                errors,
                isValid,
                isSubmitting,
                handleBlur,
                touched,
                setFieldValue
              }) => (
                <div>
                  <form onSubmit={handleSubmit} encype="multipart/form-data">
                    <div className='my-2'>

                      <div className='form-group my-2'>
                        <div>
                          <label htmlFor="yourname">Name</label>
                        </div>
                        <Field
                          id="yourname"
                          name="yourname"
                          value={values.yourname}
                          placeholder="Your name"
                          onBlur={handleBlur('yourname')}
                          autoCapitalize="none"
                          style={{
                            padding: 10,
                          }}
                          className="form-control"
                        />
                        {touched.yourname && errors.yourname && <div className='py-1'>
                          <span className='text-danger'>{touched.yourname && errors.yourname}</span>
                        </div>}
                      </div>

                      <div className='form-group my-2'>
                        <div>
                          <label htmlFor="youremail">Email address</label>
                        </div>
                        <Field
                          id="youremail"
                          name="youremail"
                          value={values.youremail}
                          placeholder="Your email"
                          onBlur={handleBlur('youremail')}
                          autoCapitalize="none"
                          style={{
                            padding: 10,
                          }}
                          className="form-control"
                        />
                        {touched.youremail && errors.youremail && <div className='py-1'>
                          <span className='text-danger'>{touched.youremail && errors.youremail}</span>
                        </div>}
                      </div>

                      <div className='form-group my-2'>
                        <div>
                          <label htmlFor="subject">Subject</label>
                        </div>
                        <Field
                          id="subject"
                          name="subject"
                          value={values.subject}
                          placeholder="Subject"
                          onBlur={handleBlur('subject')}
                          autoCapitalize="none"
                          style={{
                            padding: 10,
                          }}
                          className="form-control"
                        />
                        {touched.subject && errors.subject && <div className='py-1'>
                          <span className='text-danger'>{touched.subject && errors.subject}</span>
                        </div>}
                      </div>


                      <div className='form-group my-1'>
                        <div>
                          <label htmlFor="message">Message</label>
                        </div>
                        <Field
                          as='textarea'
                          id="message"
                          name="message"
                          value={values.message}
                          placeholder="Message"
                          onBlur={handleBlur('message')}
                          autoCapitalize="none"
                          style={{
                            padding: 10
                          }}
                          rows='4'
                          className="form-control"
                        />
                        {touched.message && errors.message && <div className='py-1'>
                          <span className='text-danger'>{touched.message && errors.message}</span>
                        </div>}
                      </div>
                    </div>

                    <button
                      type='submit'
                      buttonType="outline"
                      onClick={handleSubmit}
                      title="Submit"
                      disabled={!isValid || isSubmitting}
                      loading={isSubmitting}
                      className='btn btn-primary'
                    >
                      Submit
                    </button>

                  </form>
                </div>
              )}
            </Formik> :
              <div className='my-1 py-1'>
                {msg !== '' ? <div className='p-2'><span className='p-2 text-dark'>{msg}</span></div> : null}
              </div>}

          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Contact;