import React, { useState, useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { SiteContext } from '../context';

const CategoriesMenu = () => {

    const {products} = useContext(SiteContext);

    let navigate = useNavigate();
  
    return (
        <div className='container my-3 py-3'>
            <h4 className='rounded bg-dark text-white px-3 py-2'>Packages</h4>

            {products.length > 0 && <div>
                {products.map((item,i) => {
              
              return (
              <div key={i}>
                  <div className='border border-secondary rounded mb-1 px-3 py-2' style={{'backgroundColor':'white'}}>
                      <Link className='text-decoration-none text-dark h6' to={`detail/${item.nameslug}`}>{item.name}</Link>
                  </div>
              </div>
              )}) 
              }
              </div>
              }
        </div>
    )
}

export default CategoriesMenu;